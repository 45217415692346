import { ClassValue, clsx } from "clsx";
import { format, differenceInDays, formatDistanceToNowStrict } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
	const date = new Date(input);
	return date.toLocaleDateString("en-US", {
		month: "long",
		day: "numeric",
		year: "numeric",
	});
}

export function formatTiming({
	date,
	pattern = "yyyy-MM-dd HH:mm:ss",
}: {
	date: string;
	pattern?: string;
}): string {
	const daysAgo = differenceInDays(new Date(), new Date(date));

	if (daysAgo === 0) {
		return "Today";
	}
	if (daysAgo <= 31) {
		return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
	}
	return `${format(new Date(date), pattern)}`;
}

export function updateUrlParam(paramName: string, paramValue: any) {
	let url = new URL(window.location.href);
	url.searchParams.set(paramName, paramValue);
	window.history.replaceState(null, "", url.toString());
}

export function removeUrlParam(paramName: string) {
	let url = new URL(window.location.href);
	url.searchParams.delete(paramName);
	window.history.replaceState(null, "", url.toString());
}

export function getWordCount(text: string) {
	return String(text || "")
		?.replace(/<[^>]+>/g, "")
		?.split(" ").length;
}

export function getTotalFAQsWordCount(array: Array<{ quick_summary?: string; summary?: string }>) {
	return array?.reduce((acc, curr) => {
		const quickSummaryCount = getWordCount(curr.quick_summary || "");
		const summaryCount = getWordCount(curr.summary || "");
		return acc + quickSummaryCount + summaryCount;
	}, 0);
}
